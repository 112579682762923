<template>
<svg data-svg-lvl-2="cover-back" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430">
<PreviewLayersCoverBackContourC1D :params="params" />
<component :params="params" :is="`PreviewLayersCoverBackPlasticP${params.plastic.coverBackComponent}`" v-if="params.plastic.coverBackComponent" />
<component :params="params" :is="`PreviewLayersCoverBackCorners${params.cornersBackComponent}`" v-if="params.cornersBackComponent" />
<component :params="params" :is="`PreviewLayersCoverBackFramesM2`" v-if="params.frames.enabledBack" />
</svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>