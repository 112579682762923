import lodash from 'lodash'
import { colors, colorsHex } from './colors'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const rubber = {
  id: 'rubber',
  // tName:  'personalizations.rubber.rubber',
  template: 'modal',
  preview: 'cover',
  originPersonalizationsIds: [
    // 2 мм, горизонтальная
    556, // Резинка круглая горизонтальная с захватом, 15х21 см
    681, // Резинка круглая горизонтальная с захватом, 2 мм
    343, // Резинка круглая 212 мм (2 мм/2 мм, горизонтальная) (для M14)
    344, // Резинка круглая 275 мм(для M14)

    // 6 мм
    247, // Резинка на обложку 6 мм

    // 10 мм
    590, // Резинка на обложку 10 мм
    655, // Металлическая шильда на резинку 10 мм_УФ печать
    656, // Металлическая шильда на резинку 10 мм_ГРАВИРОВКА

    // 15 мм (без шильды с шильдой)
    591, // Резинка на обложку 15 мм
    593, // Изготовление шильды 115х16 +установка резинки 15 мм
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getRubber(params)
  getNameplate(params)
  getDrawing(params)
  // getNameplateMaking(params)
  getColor(params)
  getMaterial(params)
  getNameplateColor(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  const originPersonalizations = this.originPersonalizations
  let idBuhta
  let idParam
  let idValue
  let idParamAdd
  let idValueAdd
  let idWork
  let value

  const keyRubber = modelValues?.rubber?.id
  const keyDrawing = modelValues?.drawing?.id
  // const keyNameplateMaking = modelValues?.nameplateMaking?.checked
  const keyColor = modelValues?.color?.id
  const keyMaterial = modelValues?.material?.id
  const keyNameplate = modelValues?.nameplate?.id
  const keyNameplateColor = modelValues?.nameplateColor?.id

  if (keyRubber) {
    if (keyRubber === '2mm') {
      if (this.originPersonalizations[343]) {
        idBuhta = 343
        idParam = 33
        idValue = keyColor
      } else if (this.originPersonalizations[344]) {
        idBuhta = 344
        idParam = 37
        idValue = keyColor
      } else if (this.originPersonalizations[556]) {
        idBuhta = 556
        idParam = 37
        idValue = keyColor
        idParamAdd = 68
        idValueAdd = keyMaterial
      } else if (this.originPersonalizations[681]) {
        idBuhta = 681
        idParam = 33
        idValue = keyColor
        idParamAdd = 68
        idValueAdd = keyMaterial
      }
    } else if (keyRubber === '6mm') {
      idBuhta = 247
      idParam = 28
      idValue = keyColor
    } else if (keyRubber === '10mm') {
      idParam = 85
      idValue = keyColor
      if (keyNameplate === 'without') {
        idBuhta = 590
      } else {
        idParamAdd = 112
        if (keyNameplateColor === 'gold') {
          if (keyNameplate === 'circle20x20') {
            idValueAdd = 2190
          } else if (keyNameplate === 'rect15x30') {
            idValueAdd = 2193
          }
        } else if (keyNameplateColor === 'silver') {
          if (keyNameplate === 'circle20x20') {
            idValueAdd = 2191
          } else if (keyNameplate === 'rect15x30') {
            idValueAdd = 2192
          }
        }
        if (keyDrawing === 'uvPrinting') {
          idBuhta = 655
          // idWork = 1607
          // value = keyNameplateMaking
        } else if (keyDrawing === 'engraving') {
          idBuhta = 656
        }
      }
    } else if (keyRubber === '15mm') {
      idParam = 86
      idValue = keyColor
      if (keyNameplate === 'without') {
        idBuhta = 591
      } else if (keyNameplate === 'rect115x16') {
        idBuhta = 593
        idParamAdd = 68
        idValueAdd = keyMaterial
      }
    }
  }

  return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [
    {
      idParam,
      idValue,
    },
    {
      idParam: idParamAdd,
      idValue: idValueAdd,
    },
    {
      idWork,
      value,
    },
  ])
}

/**
 * Выбор Резинки
 */
function getRubber(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  const values = []
  const personals = {
    '2mm': [343, 344, 556, 681],
    '6mm': [247], //, 368, 369, 370, 314, 565
    '10mm': [590, 655, 656],
    '15mm': [591, 593],
  }

  for (const key in personals) {
    let ids = lodash.intersection(originIdsAvailable, personals[key])
    if (ids.length > 0) {
      values.push({
        id: key,
        tName: `personalizations.rubber.${key}`,
        img: `${cnstrAssetsUrl}/personalizations/rubber/${key}.webp`,
        idBuhta: ids[0],
      })
    }
  }
  if (values.length > 0) {
    items.push({
      id: 'rubber',
      tName: 'personalizations.rubber.rubber',
      template: 'selectImageText',
      sort: 10,
      values,
    })

    if (oldModelValues.rubber) {
      modelValues.rubber = values.find((i) => i.id === oldModelValues.rubber.id) ?? values[0]
    } else {
      modelValues.rubber = values[0]
    }
  }
}

/**
 * Выбор шильды
 */
function getNameplate(params) {
  const {
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
    store: {
      CONST: { cnstrAssetsUrl },
    },
  } = params

  let idsBuhta = []
  let idParam

  if (modelValues.rubber.id === '10mm') {
    idsBuhta = [590, 655, 656]
    idParam = 112
  } else if (modelValues.rubber.id === '15mm') {
    idsBuhta = [591, 593]
  } else {
    return
  }

  const values = []
  for (const idBuhta of idsBuhta) {
    if (originPersonalizations[idBuhta]) {
      let id
      if (idBuhta === 590 || idBuhta === 591) id = 'without'
      if (idBuhta === 655 || idBuhta === 656) {
        const param = originPersonalizations[idBuhta].params.find((i) => i.id === idParam)
        if (param.values.find((p) => p.id === 2190 || p.id === 2191)) {
          if (!values.find((k) => k.id === 'circle20x20')) {
            values.push({
              id: 'circle20x20',
              tName: `personalizations.rubber.circle20x20`,
              img: `${cnstrAssetsUrl}/personalizations/rubber/circle20x20.webp`,
            })
          }
        }
        if (param.values.find((p) => p.id === 2192 || p.id === 2193)) {
          if (!values.find((k) => k.id === 'rect15x30')) {
            values.push({
              id: 'rect15x30',
              tName: `personalizations.rubber.rect15x30`,
              img: `${cnstrAssetsUrl}/personalizations/rubber/rect15x30.webp`,
            })
          }
        }
      }

      if (idBuhta === 593) id = 'rect115x16'
      if (id) {
        values.push({
          id,
          tName: `personalizations.rubber.${id}`,
          img: `${cnstrAssetsUrl}/personalizations/rubber/${id}.webp`,
          idBuhta,
        })
      }
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'nameplate',
      tName: 'personalizations.rubber.nameplate',
      template: 'selectClassicImage',
      sort: 30,
      values,
    })

    if (oldModelValues.nameplate) {
      modelValues.nameplate = values.find((i) => i.id === oldModelValues.nameplate.id) ?? values[0]
    } else {
      modelValues.nameplate = values[0]
    }
  }
}

/**
 * Выбор типа нанесения
 */
function getDrawing(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  if (
    modelValues.rubber.id !== '10mm' ||
    !(modelValues.nameplate.id === 'circle20x20' || modelValues.nameplate.id === 'rect15x30')
  ) {
    return
  }

  const idsBuhta = [655, 656]
  const values = []
  for (const idBuhta of idsBuhta) {
    if (originPersonalizations[idBuhta]) {
      let id
      if (idBuhta === 655) id = 'uvPrinting'
      if (idBuhta === 656) id = 'engraving'
      values.push({
        id,
        tName: `personalizations.rubber.${id}`,
        idBuhta,
      })
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'drawing',
      tName: 'personalizations.rubber.drawing',
      template: 'selectRadio',
      sort: 70,
      values,
    })

    if (oldModelValues.drawing) {
      modelValues.drawing = values.find((i) => i.id === oldModelValues.drawing.id) ?? values[0]
    } else {
      modelValues.drawing = values[0]
    }
  }
}

/**
 * Выбор флага изготовления шильды
 */
// function getNameplateMaking(params) {
//   const { oldModelValues, items, modelValues, originPersonalizations } = params
//   if (modelValues.drawing === undefined || modelValues.drawing.id !== 'uvPrinting') {
//     return
//   }
//   let values = []
//   const param = originPersonalizations[modelValues.drawing.idBuhta].params.find((item) => Number(item.idWork) === 1607)

//   const checked = Number(param.InOut) === 1
//   values = [
//     { id: 0, idWork: param.idWork, checked },
//     { id: 1, idWork: param.idWork, checked: !checked },
//   ]

//   if (values.length > 0) {
//     items.push({
//       id: 'nameplateMaking',
//       tName: 'personalizations.rubber.nameplateMaking',
//       template: 'checkbox',
//       sort: 80,
//       values,
//     })
//     if (oldModelValues.nameplateMaking) {
//       modelValues.nameplateMaking =
//         values.find((i) => i.checked === oldModelValues.nameplateMaking.checked) ?? values[0]
//     } else {
//       modelValues.nameplateMaking = values[0]
//     }
//   }
// }

/**
 * Выбор цвета резинки
 */
function getColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta
  let idParam

  if (modelValues.rubber.id === '2mm') {
    if (originPersonalizations[343]) {
      idBuhta = 343
      idParam = 33
    } else if (originPersonalizations[344]) {
      idBuhta = 344
      idParam = 37
    } else if (originPersonalizations[556]) {
      idBuhta = 556
      idParam = 37
    } else if (originPersonalizations[681]) {
      idBuhta = 681
      idParam = 33
    }
  } else if (modelValues.rubber.id === '6mm') {
    idBuhta = 247
    idParam = 28
  } else if (modelValues.rubber.id === '10mm') {
    if (modelValues.drawing) {
      idBuhta = modelValues.drawing.idBuhta
    } else {
      idBuhta = modelValues.nameplate.idBuhta
    }

    idParam = 85
  } else if (modelValues.rubber.id === '15mm') {
    idBuhta = modelValues.nameplate.idBuhta
    idParam = 86
  } else {
    return
  }

  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => ({
        ...n,
        background: n.hex,
      }))
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'color',
      tName: 'personalizations.rubber.color',
      template: 'selectClassicColor',
      sort: 20,
      values,
    })

    if (oldModelValues.color) {
      modelValues.color = values.find((i) => i.id === oldModelValues.color.id) ?? values[0]
      // hex в  url path не работает, потому что содержит # решетку
      // values.find((i) => i.hex === oldModelValues.color.hex) ?? values[0]
    } else {
      modelValues.color = values[0]
    }
  }
}

/**
 * Выбор материала
 */
function getMaterial(params) {
  const {
    store: {
      materialsTexturesKeys,
      materialsColorsKeys,
      selectedMaterials,
      CONST: { assetsUrl },
    },
    oldModelValues,
    items,
    modelValues,
    originPersonalizations,
  } = params
  let idBuhta
  let material = 'material'
  let idParam = 68
  if (modelValues.rubber.id === '2mm') {
    material = 'materialGrip'
    if (originPersonalizations[556]) {
      idBuhta = 556
    } else if (originPersonalizations[681]) {
      idBuhta = 681
    } else {
      return
    }
  } else if (modelValues.rubber.id === '15mm') {
    idBuhta = modelValues.nameplate.idBuhta
    material = 'materialNameplate'
  } else {
    return
  }
  let values = []
  if (originPersonalizations[idBuhta].params) {
    const val = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)
    if (val?.values?.length) {
      values = val.values.map((n) => {
        let textureRef = materialsTexturesKeys[n.textureId]
        let colorRef = materialsColorsKeys[n.colorId]
        let hint = ``
        if (textureRef && colorRef) {
          hint = `${textureRef.key} ${textureRef.name}<br>${colorRef.name} ${colorRef.key}`
        }
        return {
          ...n,
          imgOrigin: `${assetsUrl}/materials/${textureRef?.key}${colorRef?.key}.jpg`,
          img: `${assetsUrl}/materials/small/${textureRef?.key}${colorRef?.key}_mini.jpg`,
          textureRef,
          colorRef,
          hint,
        }
      })
      // сортировка материалов и перемещение наверх материала, который является материалом обложки
      values = lodash.sortBy(values, ['colorRef.sort', 'textureRef.key'])
      let matId = values.findIndex(
        (n) => n.colorId === selectedMaterials[0].color && n.textureId === selectedMaterials[0].texture,
      )
      if (matId > 0) {
        values.unshift(values[matId])
        values.splice(matId + 1, 1)
      }
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'material',
      tName: `personalizations.rubber.${material}`,
      template: 'selectImage',
      useFilter: true,
      sort: 50,
      values,
    })

    if (oldModelValues.material) {
      modelValues.material = values.find((i) => i.id === oldModelValues.material.id) ?? values[0]
    } else {
      modelValues.material = values[0]
    }
  }
}

/**
 * Выбор цвета шильды
 */
function getNameplateColor(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let idBuhta
  let idParam

  if (
    modelValues.rubber.id === '10mm' &&
    modelValues.drawing &&
    (modelValues.drawing.id === 'engraving' ||
      modelValues.drawing.id === 'uvPrinting' ||
      (modelValues.nameplateMaking && modelValues.nameplateMaking.checked === true))
  ) {
    idBuhta = modelValues.drawing.idBuhta
    idParam = 112
  } else {
    return
  }

  const values = []
  if (originPersonalizations[idBuhta].params) {
    const param = originPersonalizations[idBuhta].params.find((item) => item.id === idParam)

    if (param?.values?.length) {
      if (param.values.find((p) => p.id === 2190 || p.id === 2193)) {
        if (!values.find((k) => k.id === 'gold')) {
          values.push({
            id: 'gold',
            tName: `personalizations.rubber.gold`,
            background: colors[32],
            hex: colorsHex[32],
          })
        }
      }

      if (param.values.find((p) => p.id === 2191 || p.id === 2192)) {
        if (!values.find((k) => k.id === 'silver')) {
          values.push({
            id: 'silver',
            tName: `personalizations.rubber.silver`,
            background: colors[33],
            hex: colorsHex[33],
          })
        }
      }
    }
  }

  if (values.length > 0) {
    items.push({
      id: 'nameplateColor',
      tName: 'personalizations.rubber.nameplateColor',
      template: 'selectClassicColor',
      mod: 'aboveSelect',
      sort: 60,
      values,
    })

    if (oldModelValues.nameplateColor) {
      modelValues.nameplateColor = values.find((i) => i.id === oldModelValues.nameplateColor.id) ?? values[0]
    } else {
      modelValues.nameplateColor = values[0]
    }
  }
}
